@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

@font-face {
  font-family: 'Radikal';
  src: local('Radikal'), url(./fonts/Radikal.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  margin: 0;

  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-body;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
  @apply text-h1 font-radikal text-swopa-secondary-light-blue;
}

h2 {
  @apply text-h2 font-radikal text-swopa-secondary-light-blue;
}

h3 {
  @apply text-h3 font-radikal text-swopa-secondary-light-blue;
}

@media (max-width: 1280px) {
  h1 {
    @apply text-h1-mobile;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* arrows */
.arrow_box {
  position: relative;
  background: #fff;
  border: 4px solid #fff;
}
.arrow_box:after,
.arrow_box:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.arrow_box:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 8px;
  margin-left: -8px;
}
.arrow_box:before {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 14px;
  margin-left: -14px;
}
