.react-datepicker {
  display: flex;
  flex-direction: column;
  border: 0;
  box-shadow: 0px 0px 8px rgba(22, 33, 56, 0.16);
}

.react-datepicker-popper {
  z-index: 9999;
}

.react-datepicker-wrapper {
  border: 1px solid #ececec;
  border-radius: 4px;
  max-width: 100px;
  overflow: hidden;
  display: block;
}

.react-datepicker__input-container input {
  padding: 8px;
  width: 100%;
  text-align: center;
  font-size: 12px;
}

.react-datepicker__header {
  background: none;
  border: 0;
  padding-top: 16px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 14px;
  font-weight: 400;
  color: #2d4370;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  margin-bottom: 12px;
}

.react-datepicker__navigation {
  top: 12px;
}

.react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0;
}

.react-datepicker__day-name {
  color: #aaaaaa;
}

.react-datepicker__day {
  color: #2d4370;
}

.react-datepicker__day:hover {
  border-radius: 50%;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background: #4091f7;
  color: #fff;
  font-weight: normal;
  border-radius: 50%;
}

.react-datepicker__today-button {
  background: none;
  border: 0;
  padding: 0;
  font-weight: 400;
  color: #aaaaaa;
  position: absolute;
  bottom: 16px;
  left: 12px;
}
