.input {
  height: 0;
  width: 0;
  visibility: hidden;
}

.label {
  cursor: pointer;
  @apply w-10;
  @apply h-5;
  @apply bg-swopa-grey-3;
  display: block;
  @apply rounded-full;
  position: relative;
  @apply pr-4;
}

.label:after {
  content: '';
  position: absolute;
  @apply top-1;
  @apply left-1;
  @apply w-3;
  @apply h-3;
  @apply bg-swopa-primary-white;
  @apply rounded-full;
  transition: 0.3s;
}

.input:checked + .label {
  @apply bg-swopa-secondary-light-blue;
}

.label:hover {
  @apply bg-swopa-grey-4;
}

.input:checked + .label:hover {
  @apply bg-swopa-secondary-light-blue-hover;
}

.input:checked + .label:after {
  left: calc(100% - 0.25rem);
  transform: translateX(-100%);
}

.label:active:after {
  @apply w-3.5;
}
